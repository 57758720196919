import { ProfileView } from "admin/src/server/mappers/profile/profile";
import { ProfileAddressView } from "admin/src/server/mappers/profile/profile-address";

//TODO: This is a bit temporary,  I'd rather the default email by set by an ID in society settings instead of this string ~ GS 8/16/23
export const defaultAddressFromProfile = (
  profile: ProfileView | ProfileView,
  use: "Billing" | "Shipping" | string,
): ProfileAddressView | null => {
  return (
    profile?.address?.find((ci) =>
      ci.use?.find((ciuse) => ciuse.description === use),
    ) ??
    profile?.address?.[0] ??
    null
  );
};
