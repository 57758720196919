/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";

import { PreviewView } from "admin/src/server/mappers/submission/preview/preview-instance";
import { newApiRequest } from "admin/src/ui/api-callouts/utils";

import { ProfileView } from "admin/src/server/mappers/profile/profile";
import { ReviewInstanceAssignmentView } from "admin/src/server/mappers/review/reviewInstanceAssignment";
import { SubmissionInstanceView } from "admin/src/server/mappers/submission/instance/submission-instance";
import MustacheTemplateComponent from "admin/src/ui/components/MustacheTemplateComponent";
import { getSocietyId } from "hub/src/utils/getSocietyId";
import { processDisplayValue } from "hub/src/utils/reviews/processDisplayValue";
import { getProfileId } from "hub/src/utils/sessionStorage/user";
import { getSocietyProfileReviewAssignmentSubmissionInstancePreviewContract } from "shared/api-contracts/society/societyId/profiles/profileId/review/assignments/reviewAssignmentId/submission/submissionInstanceId/preview";

//Check with Gary on this
const excludedQuestionTypes = ["profilesearchmultiple", "products"];

export type ReviewsTabsType = {
  submissionInstance?: SubmissionInstanceView;
  disclosureInstance?: SubmissionInstanceView;
  profile: ProfileView;
  role?: string;
};

type SubmissionInstanceTabProps = {
  reviewInstanceAssignment: ReviewInstanceAssignmentView;
} & ReviewsTabsType;

const SubmissionInstanceTab = ({
  reviewInstanceAssignment,
  submissionInstance,
  disclosureInstance,
  profile,
  role,
}: SubmissionInstanceTabProps) => {
  const [instancePreview, setInstancePreview] = useState<PreviewView | null>(
    null,
  );
  const [disclosurePreview, setDisclosurePreview] =
    useState<PreviewView | null>(null);

  useEffect(() => {
    if (submissionInstance) {
      newApiRequest(
        getSocietyProfileReviewAssignmentSubmissionInstancePreviewContract,
        {
          params: {
            societyId: getSocietyId(),
            reviewAssignmentId: Number(
              reviewInstanceAssignment?.reviewInstanceAssignmentId,
            ),
            submissionInstanceId: submissionInstance?.id,
            profileId: getProfileId(),
          },
        },
      ).then((response) => {
        setInstancePreview(response);
      });
    }
    if (disclosureInstance) {
      newApiRequest(
        getSocietyProfileReviewAssignmentSubmissionInstancePreviewContract,
        {
          params: {
            societyId: getSocietyId(),
            reviewAssignmentId: Number(
              reviewInstanceAssignment?.reviewInstanceAssignmentId,
            ),
            submissionInstanceId: disclosureInstance.id,
            profileId: getProfileId(),
          },
        },
      ).then((response) => {
        setDisclosurePreview(response);
      });
    }
  }, []);

  const processAndReduceData = (previewData: PreviewView | null) => {
    if (!previewData) return null;

    return previewData.body
      .filter((item) => !excludedQuestionTypes.includes(item.questionType))
      .reduce(
        (acc, item) => {
          const processedItem = {
            ...item,
            titleHtml: item.title,
            ...processDisplayValue(item.displayValue, item.questionType),
          };

          acc[item.name] = processedItem;
          acc.body.push(processedItem);

          return acc;
        },
        {
          ...previewData,
          body: [],
        } as any,
      );
  };

  const dataForTemplate = {
    submission_instance_id: submissionInstance?.id,
    disclosure_instance_id: disclosureInstance?.id,
    submissionInstance,
    disclosureInstance,
    profile,
    role,
    reviewInstanceAssignment,
  };

  const templateData = {
    ...dataForTemplate,
    instancePreview: processAndReduceData(instancePreview),
    disclosurePreview: processAndReduceData(disclosurePreview),
  };

  return (
    <>
      {instancePreview && (
        <MustacheTemplateComponent
          className="pb-2"
          template={
            reviewInstanceAssignment?.session?.submissionTemplate ??
            "No Template Found"
          }
          data={{
            ...templateData,
            ...templateData.instancePreview,
            ...templateData.submissionInstance,
          }}
        />
      )}
      {disclosurePreview && (
        <MustacheTemplateComponent
          className="pt-2"
          template={
            reviewInstanceAssignment?.session?.disclosureTemplate ??
            "No Template Found"
          }
          data={{
            ...templateData,
            ...templateData.disclosurePreview,
            ...templateData.disclosureInstance,
          }}
        />
      )}
    </>
  );
};
export default SubmissionInstanceTab;
