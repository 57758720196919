/* eslint-disable @typescript-eslint/no-explicit-any */
import { SessionView } from "admin/src/server/mappers/session/session";
import React from "react";
import { ElementFactory, Question, Serializer, settings } from "survey-core";
import { SurveyCreator } from "survey-creator-react";
import { ReactQuestionFactory } from "survey-react-ui";
import SurveyJSProfileSearch, {
  SurveyJSProfileSearchProps,
} from "../../components/SurveyJSProfileSearch";

export const CUSTOM_TYPE_PROFILE_SEARCH = "profilesearch";

export class ProfileSearchQuestionModel extends Question {
  getType() {
    return CUSTOM_TYPE_PROFILE_SEARCH;
  }
}

(settings as { customIcons: { [index: string]: string } }).customIcons[
  "icon-" + CUSTOM_TYPE_PROFILE_SEARCH
] = "icon-search";

export const NewProfileSearchQuestionProperties = {
  directoryId: "directoryId",
  newProfileWorkflowId: "newProfileWorkflowId",
  emailRequired: "emailRequired",
  affiliationRequired: "affiliationRequired",
  firstNameRequired: "firstNameRequired",
  lastNameRequired: "lastNameRequired",
};

export type ProfileSearchQuestionComponentData = {
  profile_id: number;
  display_name?: string;
  affiliation?: string;
};
export type NewProfileSearchQuestionInputProps = {
  creator: SurveyCreator;
  isDisplayMode: boolean;
  question: Question;
  session: SessionView;
  baseUrl: string;
};
export type ProfileSearchQuestionData = {
  profile_id: number;
  display_name?: string;
  affiliation?: string;
};
export const NewProfileSearchQuestion = (
  props: NewProfileSearchQuestionInputProps,
) => {
  props.question.titleLocation = "hidden";
  props.question.errorLocation = "bottom";
  const existingValue = props.question.value;
  const newProfileWorkflowId = props.question.getPropertyValue(
    NewProfileSearchQuestionProperties.newProfileWorkflowId,
  );
  const directoryId = props.question.getPropertyValue(
    NewProfileSearchQuestionProperties.directoryId,
  );
  const emailRequired = props.question.getPropertyValue(
    NewProfileSearchQuestionProperties.emailRequired,
  );
  const affiliationRequired = props.question.getPropertyValue(
    NewProfileSearchQuestionProperties.affiliationRequired,
  );
  const firstNameRequired = props.question.getPropertyValue(
    NewProfileSearchQuestionProperties.firstNameRequired,
  );
  const lastNameRequired = props.question.getPropertyValue(
    NewProfileSearchQuestionProperties.lastNameRequired,
  );

  return SurveyJSProfileSearch({
    handleChanged: (val: { profile: ProfileSearchQuestionData }) => {
      props.question.value = val.profile;
    },
    directoryId,
    workflowId: newProfileWorkflowId,
    session: props.session,
    existingValue,
    emailRequired,
    affiliationRequired,
    firstNameRequired,
    lastNameRequired,
  } as SurveyJSProfileSearchProps);
};

export const RegisterProfileSearchQuestionComponent = (
  session: SessionView,
  baseUrl?: string,
) => {
  ElementFactory.Instance.registerElement(
    CUSTOM_TYPE_PROFILE_SEARCH,
    (name) => {
      return new ProfileSearchQuestionModel(name);
    },
  );

  Serializer.addClass(
    CUSTOM_TYPE_PROFILE_SEARCH,
    [{ name: "height", default: "200px", category: "layout" }],
    function () {
      return new ProfileSearchQuestionModel("");
    },
    "question",
  );

  Serializer.addProperty(CUSTOM_TYPE_PROFILE_SEARCH, {
    name: "directoryId",
    displayName: "Choose a directory to search for profiles",
    type: "dropdown",
    isRequired: true,
    choices: [],
    category: "general",
  });

  Serializer.addProperty(CUSTOM_TYPE_PROFILE_SEARCH, {
    name: "newProfileWorkflowId",
    displayName: "Choose a workflow",
    type: "dropdown",
    isRequired: true,
    choices: [],
    category: "general",
  });

  Serializer.addProperty(CUSTOM_TYPE_PROFILE_SEARCH, {
    name: "emailRequired",
    displayName: "Email",
    type: "boolean",
    isRequired: false,
    default: false,
    category: "Create Profile Required Fields",
  });

  Serializer.addProperty(CUSTOM_TYPE_PROFILE_SEARCH, {
    name: "affiliationRequired",
    displayName: "Affiliation",
    type: "boolean",
    isRequired: false,
    default: false,
    category: "Create Profile Required Fields",
  });

  Serializer.addProperty(CUSTOM_TYPE_PROFILE_SEARCH, {
    name: "firstNameRequired",
    displayName: "First name",
    type: "boolean",
    isRequired: false,
    default: false,
    category: "Create Profile Required Fields",
  });

  Serializer.addProperty(CUSTOM_TYPE_PROFILE_SEARCH, {
    name: "lastNameRequired",
    displayName: "Last name",
    type: "boolean",
    isRequired: false,
    default: false,
    category: "Create Profile Required Fields",
  });

  Serializer.getProperty(CUSTOM_TYPE_PROFILE_SEARCH, "name").readOnly = true;

  ReactQuestionFactory.Instance.registerQuestion(
    CUSTOM_TYPE_PROFILE_SEARCH,
    (props: any) => {
      return React.createElement(NewProfileSearchQuestion, {
        ...props,
        session,
        baseUrl,
      });
    },
  );
};
